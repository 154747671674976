import { createRouter, createWebHistory } from 'vue-router';
import ServicesPage from '../pages/ServicesPage.vue';
import LoginPage from '../pages/LoginPage.vue';
import RegisterPage from '../pages/RegisterPage.vue';
import ResetPasswordPage from '../pages/ResetPasswordPage.vue';
import DashboardPage from '../pages/DashboardPage.vue';
import Profil from '../pages/ProfilPage.vue';
import Security from '../pages/SecurityPage.vue';
import ForgotPasswordPage from '../pages/ForgotPasswordPage.vue';
import authMiddleware from '../middlewares/authMiddleware.js';
import specificSpaceMiddleware from '../middlewares/specificSpaceMiddleware.js';
import NotFound from '../pages/NotFoundPage.vue';
import CGUPage from '../pages/CGUPage.vue';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage.vue';
import CookiesPolicyPage from '../pages/CookiesPolicyPage.vue';
import connectAsMiddleware from '../middlewares/connectAsMiddleware.js';
import { useLoaderStore } from '../store/loader.js';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardPage,
    meta: {
      auth: true,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/profil',
    name: 'Profil',
    component: Profil,
    meta: {
      auth: true,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/connect-as',
    name: 'connect-as',
    beforeEnter: connectAsMiddleware,
  },
  {
    path: '/security',
    name: 'ChangePassword',
    component: Security,
    meta: {
      auth: true,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/my-services',
    name: 'Services',
    component: ServicesPage,
    meta: {
      auth: true,
      validatedAccountRequired: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPasswordPage,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/conditions-generales-utilisation',
    name: 'CGU',
    component: CGUPage,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/politique-de-confidentialite',
    name: 'PrivacyPolicy',
    component: PrivacyPolicyPage,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/politique-de-cookies',
    name: 'CookiesPolicy',
    component: CookiesPolicyPage,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const loaderStore = useLoaderStore();
  loaderStore.showLoader();
  await authMiddleware(to, from, next);
  specificSpaceMiddleware(next);
});

router.afterEach(() => {
  const loaderStore = useLoaderStore();
  loaderStore.hideLoader();
});

export default router;
