<template>
  <div class="container d-flex flex-row">
    <form
      class="form flex-1 p-6"
      :style="{ '--first-color': firstColor, '--second-color': secondColor }"
      @submit.prevent="login"
    >
      <div class="logo">
        <img
          v-if="logo"
          :src="logo"
          alt="logo"
          class="mt-2"
        >
        <img
          v-else
          src="../assets/images/logo.png"
          alt="logo"
        >
      </div>
      <div
        v-if="loginError"
        class="error-message ml-2"
      >
        <p>Oups ! Un problème est survenu.</p>
        <ul>
          <li class="list-style">
            Ces identifiants ne correspondent pas à nos enregistrements.
          </li>
        </ul>
      </div>
      <div
        v-if="verificationPendingError"
        class="error-message ml-2"
      >
        <p>Oups ! Un problème est survenu.</p>
        <ul>
          <li class="list-style">
            Votre compte est en cours de validation.
          </li>
        </ul>
      </div>
      <div
        v-if="verificationArchivedError"
        class="error-message ml-2"
      >
        <p>Votre compte est bloqué, merci de nous contacter. </p>
      </div>
      <div class="form-group ml-2 mt-2 mb-1">
        <label for="email">E-mail</label>
        <input
          id="email"
          v-model="email"
          class="p-0-5"
          type="email"
          name="email"
          required
        >
      </div>
      <div class="form-group ml-2 mt-2 mb-1">
        <label for="password">Mot de passe</label>
        <input
          id="password"
          v-model="password"
          class="p-0-5"
          type="password"
          name="password"
          required
        >
      </div>
      <div class="remember-me ml-2 mt-2 mb-1">
        <div class="d-flex align-items-center">
          <input
            id="rememberMe"
            type="checkbox"
            name="rememberMe"
          >
          <label>
            Se souvenir de moi
          </label>
        </div>
        <div class="forgot-password">
          <router-link to="/forgot-password">
            Mot de passe oublié ?
          </router-link>
        </div>
      </div>
      <button
        class="button border ml-2 mt-1 p-1"
        type="submit"
      >
        Connexion
      </button>
      <div
        class="register ml-2 mt-2"
        :class="isGenericPortal ? '' : 'register-specific-space'"
      >
        <span class="mr-1">Pas encore enregistré ? </span>
        <router-link
          to="/register"
          class="button-register p-0-5"
          :class="isGenericPortal ? '' : 'button-register-specific-space'"
        >
          Créer un compte
        </router-link>
      </div>
      <div class="decoration" />
    </form>
    <div
      class="main-discover flex-2 d-flex mt-4 justify-content-center"
      :style="{ '--first-color': firstColor, '--second-color': secondColor }"
    >
      <div
        v-if="services.length > 0"
        class="services-container"
      >
        <p class="discover-title">
          Parmi les services mis à votre disposition...
        </p>
        <div
          v-for="(service, index) in services"
          :key="index"
          :class="['services', index === 0 ? 'first-service' : 'second-service mt-1 mb-1']"
          :style="{ zIndex: activeServiceIndex === index ? 10 : 1 }"
          @click="setActiveService(index)"
        >
          <div class="services">
            <div class="container-image">
              <div
                v-if="getMediaType(service) === 'video'"
                class="container-media"
              >
                <video
                  class="video"
                  poster="../assets/images/logo099BlackBackground.png"
                  controls
                >
                  <source
                    :src="getVideoSource(service)"
                    type="video/mp4"
                  >
                </video>
              </div>
              <div
                v-else
                class="container-media"
              >
                <img
                  :src="`${apiBaseMedia}${service.media}`"
                  :alt="service.label"
                >
              </div>
            </div>

            <div class="container-content p-2">
              <div class="container-title">
                {{ service.label }}
              </div>
              <div class="d-flex flex-row">
                <div class="container-description">
                  <p v-html="service.description" />
                </div>
                <div class="container-logo">
                  <img
                    :src="`${apiBaseMedia}${service.logo}`"
                    :alt="service.label"
                    class="h-4"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="discover-title"
      >
        <p>
          Connectez-vous et découvrez tous les services mis à votre
          disposition...
        </p>
      </div>
      <div
        v-if="services.length === 0"
        class="dicover-video"
      />
    </div>
  </div>
  <div
    v-if="showCookieBanner"
    class="cookie-banner"
  >
    099ans n’utilise que des cookies techniques, strictement nécessaires au fonctionnement du site et ne nécessitant pas le consentement. Pour en savoir plus, veuillez consulter la
    <router-link
      class="link-footer-color"
      to="/politique-de-confidentialite"
      target="_blank"
    >
      <span class="privacy-policy">
        Politique de confidentialité
      </span>
    </router-link>.
    <button
      class="cookie-button"
      @click="showCookieBanner = false"
    >
      D'accord.
    </button>
  </div>

  <FooterComponent />
</template>
<script>
import { useAuthStore } from '../store/store.js';
import FooterComponent from '../components/FooterComponent.vue';
import { getCompany } from '../services/api.js';

export default {
  components: {
    FooterComponent,
  },
  data() {
    return {
      email: '',
      password: '',
      loginError: false,
      verificationPendingError: false,
      verificationArchivedError: false,
      logo: null,
      firstColor: null,
      secondColor: null,
      services: [],
      nb_services: 2,
      activeServiceIndex: null,
      apiBaseMedia: process.env.VUE_APP_BASE_MEDIA_URL,
      showCookieBanner: true,
      authStore: useAuthStore(),
      currentSubDomainPage: window.location.hostname.split('.')[0],
      isGenericPortal: window.location.hostname.split('.')[0] === process.env.VUE_APP_MAIN_SUB_DOMAIN,
    };
  },
  async mounted() {
    if (this.authStore.isAuth) {
      this.$router.push('/');
    }
    if (!this.isGenericPortal) {
      try {
        const company = (await getCompany({ subdomain: this.currentSubDomainPage, nb_services: this.nb_services })).data;
        this.logo = this.apiBaseMedia + company.logo;
        this.firstColor = company.color_1;
        this.secondColor = company.color_2;
        this.services = company.services;
      } catch (error) {
        this.$router.push({ name: 'NotFound' });
      }
    }
  },
  methods: {
    setActiveService(index) {
      this.activeServiceIndex = index;
    },
    getMediaType(service) {
      return service.media ? 'media' : 'video';
    },
    getVideoSource(service) {
      return `${this.apiBaseMedia}${service.video_1080 || service.video_720 || service.video_480 || service.video_360 || ''}`;
    },
    async login() {
      this.loginError = false;
      this.verificationPendingError = false;
      try {
        const payload = {
          email: this.email,
          password: this.password,
          device_name: 'WEB',
          company_subdomain: this.isGenericPortal ? undefined : this.currentSubDomainPage,
        };
        await this.authStore.login(payload, this.isGenericPortal);
        const loggedUser = this.authStore.getUser;
        if (
          loggedUser
            && loggedUser.verification_status === 'pending'
            && loggedUser.status !== 'ARCHIVED'
            && !loggedUser.company?.employees_number_required
        ) {
          this.verificationPendingError = true;
          this.authStore.logout();
        } else if (loggedUser && loggedUser.status === 'ARCHIVED') {
          this.verificationArchivedError = true;
          this.authStore.logout();
        } else if (this.isGenericPortal) {
          document.cookie = `token=${this.authStore.getToken}; path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};`;
          window.location.href = `${process.env.VUE_APP_HOST_HTTP}://${loggedUser.company.subdomain}.${window.location.hostname.substring(window.location.hostname.indexOf('.') + 1)}:${process.env.VUE_APP_HOST_PORT}/`;
        } else {
          this.$router.push('/');
        }
      } catch (error) {
        this.loginError = true;
        console.error('Login error:', error);
      }
    },

  },
};
</script>

<style scoped lang="scss">

.services{
  background-color: #fff;
  width: 27rem;
  border-radius: 1rem;
  color: #052f5f;
  position: relative;
  cursor: pointer;

}

.first-service{
  position: relative;
  right: 6rem;
  top: 5rem;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    margin-right: -135px;
    right: 11%;
    top:-82px;
    background-color: var(--first-color);
    z-index: -1;
    }
}

.second-service{
  position: relative;
  bottom: 14rem;
  left: 7rem;
  &::after {
    content: "";
    position: absolute;
    width: 170px;
    height: 170px;
    background-color: var(--second-color);
    z-index: -1;
    left: -130px;
    top: 354px;
    }
}
.container-image{
  height: 100%;
}

.container-title{
  font-size: 24px;
  font-weight: bold;
}

.container-logo img{
  width: 18rem;
  height: 2rem;
}

.video{
  width: 100%;
  border-radius: 1rem 1rem 0 0;
}

.form {
  position: relative;
  background-color: #fff;
  &::before {
    content: "";
    position: absolute;
    background-color: var(--first-color, #052f5f);
    height: 120px;
    top: 0;
    left:0;
    width: 120px;
  }

  &::after {
    content: "";
    position: absolute;
    background-color: var(--second-color, #f8485e);
    height: 85px;
    right:-42px;
    top: 0;
    width: 85px;
  }
}

.decoration{
  background-color: var(--second-color, #f8485e);
  bottom: 0;
  position: absolute;
  left: 0px;
  height: 75px;
  width: 75px;
}
.logo {
  max-width: 290px;
  margin-left: 32px;
  margin-bottom: 60px;
  align-self: start;
}

.form-group {
  label {
    display: block;
    font-weight: bold;
    color: #052f5f;
  }

  input[type="email"],
  input[type="password"] {
    width: 100%;
    max-width: 355px;
    border: none;
    border-bottom: 1px solid #052f5f;
    height:32px;
    font-size: 1em;
  }
}

.remember-me {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width:355px;
  label {
    font-weight: bold;
    color: #052f5f;
  }
}
.forgot-password {
    display:block;
    a {
      font-size: 0.8em;
      color: #999;
    }
  }
.button {
  max-width: 370px;
  width:100%;
  background-color: #fff;
  color: #052f5f;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

button:hover {
  background-color: #052f5f;
  color: #fff;
}

.button-register {
  background-color: #f8485e;
  border: #f8485e;
  line-height: 1.5rem;
  color: #fff;
  font-size: 12px;
  &-specific-space {
    background-color: v-bind(firstColor);
    border: v-bind(firstColor);
  }
}

.register {
  color: #f8485e;
  font-size: 13px;
  &-specific-space {
    color: v-bind(firstColor)
  }
}

.main-discover {
  width:50vw;
  display: flex;
  flex-direction: column;
  align-items:center;

  .discover-title {
    font-size: 32px;
    color: #052f5f;
    font-weight: 700;
    text-align: center;
    max-width: 500px;
    min-width:300px;
  }

  .dicover-video {
    display: flex;
    justify-content: center;
    position: relative;
    width:70%;
    min-height:270px;
    &::before {
      content: "";
      position: absolute;
      width: 270px;
      height: 270px;
      margin-right:-135px;
      right: 50%;
      background-color: #f8485e;
    }
    &::after {
      content: "";
      position: absolute;
      width: 120px;
      height: 120px;
      margin-left:75px;
      left: 50%;
      top: 75px;
      background-color: #052f5f;
    }
  }

}

.error-message {
  color: red;
}

.list-style {
  list-style: inside;
}

@media (max-width: 1250px) {
  .services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .first-service,
  .second-service {
    position: static;
    &::before,
    &::after {
      content: none;
    }
  }

  .logo {
    align-self: center;
  }
}

@media (max-width: 1100px) {
  .main-discover {
    display: none;
  }

  .container {
    justify-content: center;
  }

  .form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    &::after {
    content: "";
    position: absolute;
    background-color: var(--second-color, #f8485e);
    height: 85px;
    right: 0px;
    top: 0;
    width: 85px;
  }

  .logo{
    width: 40%;
  }
  }

  .form-group, .remember-me{
    width: 325px;
  }
  .button {
    width: 325px;
    display: block;
  }
 .register {
    width: 325px;
    display: block;
    margin-bottom: 4rem;
  }

  .decoration{
  height: 50px;
  width: 50px;
}

}

.cookie-banner{
  background-color: #fff;
  padding: 0.5rem;
  color: #052f5f;
  position: fixed;
  bottom: 0px;
  z-index: 99;
  width: 100%;
}

.privacy-policy{
  color: #f8485e;
}

.cookie-button{
  background-color: #fff;
  color: #052f5f;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  padding: 0.5rem 2rem;
  border: 2px solid #052f5f;
}

.cookie-button:hover{
  background-color: #052f5f;
  color: #fff;
}

</style>
