import {
  defineStore,
} from 'pinia';
import * as Sentry from '@sentry/vue';
import {
  resetPassword,
  login,
  register,
  updateProfil,
  getUserInformation,
} from '../services/api.js';
import getCookie from '../utils/cookie.js';

const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem('token') || '',
    status: '',
    isAuthenticated: !!localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')) || {},
    adminConnectAs: false,
  }),
  getters: {
    authStatus: (state) => state.status,
    isAuth: (state) => state.isAuthenticated,
    getUser: (state) => state.user,
    getToken: (state) => state.token,
  },
  actions: {
    setToken(token) {
      this.token = token;
      localStorage.setItem('token', token);
    },
    setAdminConnectAs(value) {
      this.adminConnectAs = value;
      localStorage.setItem('adminConnectAs', value);
    },
    setUserPhoneVerifiedAt(verifiedAt) {
      this.user.phone_verified_at = verifiedAt;
      localStorage.setItem('user', JSON.stringify(this.user));
    },
    setUserVerificationStatusAccepted() {
      this.user.verification_status = 'accepted';
      localStorage.setItem('user', JSON.stringify(this.user));
    },
    setUserVerificationNeeded() {
      this.user.verification_needed = true;
      localStorage.setItem('user', JSON.stringify(this.user));
    },
    setHasProfExpressAccount() {
      this.user.has_prof_express_account = true;
      localStorage.setItem('user', JSON.stringify(this.user));
    },
    async login(credentials, genericPortal = false) {
      try {
        const response = await login(credentials);
        const { token, user } = response.data;
        this.token = token;
        this.status = 'login_success';
        this.user = user;
        this.isAuthenticated = true;
        if (genericPortal) {
          document.cookie = `token=${token}; path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};`;
        } else {
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
        }
        return response;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
    logout() {
      try {
        this.token = '';
        this.user = {};
        this.status = '';
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('adminConnectAs');
        this.adminConnectAs = false;
        document.cookie = `token=;path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};expires=Thu, 01 Jan 1970 00:00:01 GMT"`;
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async register(userData, genericPortal = false) {
      try {
        const response = await register(userData);
        this.status = 'registration_success';
        if (!genericPortal) {
          const { token, user } = response.data;
          this.token = token;
          this.user = user;
          this.isAuthenticated = true;
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          document.cookie = `token=${token}; path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};`;
        }
        return response;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    async updateProfil(userData) {
      try {
        const response = await updateProfil(userData);
        this.status = 'update_success';
        this.user = { ...this.user, ...response.data };
        localStorage.setItem('user', JSON.stringify(this.user));
        return response;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    async resetPassword(payload) {
      try {
        const response = await resetPassword(payload);
        const { token, user } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        this.token = token;
        this.user = user;
        this.isAuthenticated = true;
        return response;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    async checkAuthentication() {
      const currentSubDomain = window.location.hostname.split('.')[0];
      let token = localStorage.getItem('token');
      let user = localStorage.getItem('user');
      if (!token || !user) {
        const cookieToken = getCookie('token');
        if (!cookieToken) {
          this.logout();
          this.isAuthenticated = false;
        } else {
          token = cookieToken;
          user = (await getUserInformation()).data;
          if (currentSubDomain !== process.env.VUE_APP_MAIN_SUB_DOMAIN) {
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            this.user = user;
            document.cookie = `token=;path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};expires=Thu, 01 Jan 1970 00:00:01 GMT"`;
          }
          this.token = token;
          this.status = 'login_success';
          this.user = user;
          this.isAuthenticated = true;
        }
      }

      if (token !== this.token) {
        this.logout();
        this.isAuthenticated = false;
      }
    },

  },
});

export {
  useAuthStore,
};
