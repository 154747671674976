import { useAuthStore } from '../store/store.js';

export default async function authMiddleware(to, from, next) {
  const auth = useAuthStore();
  await auth.checkAuthentication();
  if (to.meta.auth && !auth.isAuth) {
    next('/login');
  } else if (
    auth.user
    && auth.user.verification_status === 'pending'
    && auth.user.company.employees_number_required
  ) {
    if (to.meta.validatedAccountRequired) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
}
